import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";

const exampleRequest = () => {
  // create a csv with a string
  const blob = new Blob(["name,number,bulkId\ndrbh,13478344779,abcd"], {
    type: "text/plain",
  });

  // build form data
  const data = new FormData();
  data.append("document", blob, "myfile.csv");
  data.append("bulkId", "abcd");
  data.append("flush", "true");

  // build request
  const xhr = new XMLHttpRequest();

  // NOTE:
  // withCredentials must be set to false for CORS to work
  //
  // The value of the 'Access-Control-Allow-Origin' header in the response must not
  // be the wildcard '*' when the request's credentials mode is 'include'.
  // The credentials mode of requests initiated by the XMLHttpRequest is controlled by
  // the withCredentials attribute.
  // - https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Access-Control-Allow-Credentials
  xhr.withCredentials = false;

  // listen for response
  xhr.addEventListener("readystatechange", function () {
    if (this.readyState === this.DONE) {
      console.log(this.responseText);
    }
  });

  xhr.open("POST", "https://tamarack.fly.dev/csv");

  xhr.send(data);
};

function App() {

  useEffect(() => {
    console.log("sending request");
    exampleRequest();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
